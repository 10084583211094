<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'rh' 
      
    "
  >
  <b-card>
      <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon
                icon="ActivityIcon"
                class="text-white mr-1"
                size="20"
              />
              STATISTIQUES PAR ARH
            </template>
            <hr />
              <div
                v-if="isAssistantRHListLoading"
                class="text-center text-info my-2"
              >
                <b-spinner
                  variant="info"
                  style="width: 3rem; height: 3rem;"
                />
              </div>
              <app-collapse
                v-else
                accordion
                type="margin"
              >
                <app-collapse-item
                  v-for="(arh, index) in getAssistantRHList"
                  :key="index"
                  :title="arh.full_name"
                  @collapse-click="getARHKpis(arh.id)"
                >
                  <b-row class="justify-content-center mx-1 mb-3">
                    <b-col
                      sm="9"
                      xs="12"
                    >
                      <b-form-group>
                        <h5>Filtrer par date</h5>
                        <flat-pickr
                          v-model="rangeDate"
                          class="form-control"
                          :config="config"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="3"
                      xs="12"
                      class="mt-2"
                    >
                      <b-button
                        :disabled="isRangeStatisticsLoading || rangeDate == null"
                        variant="primary"
                        @click="
                          rangeDate = null;
                          filterIsActive = false;
                          getARHKpis(arh.id);
                        "
                      >
                        {{ isRangeStatisticsLoading ? "Chargement..." : "Effacer" }}
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row>
                      <b-col
            
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-2"
                        >
                          <b-button
                            variant="primary"
                            @click="resetARHStatistics()"
                          >
                            <div v-if="isReLoadDataTaux">
                              <span> Chargement ... </span>
                              <b-spinner small />
                            </div>
                            <span v-else class="text-nowrap font-medium-1">Actualiser</span>
                          </b-button>
                      </b-col>
              
                  </b-row>

                  <div
                    v-if="isNumbersStatisticsLoading"
                    class="text-center text-info my-2"
                  >
                    <b-spinner
                      variant="info"
                      style="width: 3rem; height: 3rem;"
                    />
                  </div>

                  <b-row v-else>
                    <b-col
                      v-for="(item , index) in Object.entries(statisticsItems)"
                      :key="index"
                      lg="4"
                      sm="6"
                      style="height:max-content !important;"
                    >

                      <statistic-card-horizontal
                        :isTaux = false
                        :icon="
                          item[0]==='Nombre d\'employé inscrit' ? 'UsersIcon':
                          item[0]==='Nombre d\'employé occupé' ? 'UserIcon':
                          item[0]==='Nombre d\'employé confirmé' ? 'UserCheckIcon':
                          item[0]==='Nombre d\'employé qui ont désisté'? 'UserMinusIcon' :
                          item[0]==='Nombre d\'employé qui ont approuvé leurs contrat non déployé' ? 'UserIcon':
                          ''"
                        color="info"
                        :statistic="item[1].toLocaleString()"
                        :statistic-title="
                          item[0]"
                      />
                    </b-col>
                  </b-row>
                </app-collapse-item>
              </app-collapse>
          </b-tab>

          <b-tab >
            <template #title>
              <feather-icon
                icon="BarChart2Icon"
                class="text-white mr-1"
                size="20"
              />
              TAUX PAR ARH
            </template>
            <hr />
              <div
                v-if="isAssistantRHListLoading"
                class="text-center text-info my-2"
              >
                <b-spinner
                  variant="info"
                  style="width: 3rem; height: 3rem;"
                />
              </div>
              <app-collapse
                v-else
                accordion
                type="margin"
              >

              <app-collapse-item
                  v-for="(arh, index) in getAssistantRHList"
                  :key="index"
                  :title="arh.full_name"
                  @collapse-click="getARHKpisTaux(arh.id)"
                >

                  <b-row class="mb-2">
                    <b-col
                      cols="12"
                      md="10"
                      lg="10"
                      class="mb-md-0 mb-2"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          sm="6"
                          md="4"
                          lg="4"
                        >
                          <label for="">Type</label>
                          <v-select
                          v-model="type"
                            :options="typeOptions"
                            class="invoice-filter-select"
                            :reduce="(item) => item.value"
                          >
                            <template #selected-option="{ label }">
                              <span class="text-truncate overflow-hidden">
                                {{ label }}
                              </span>
                            </template>
                          </v-select>
                        </b-col>

                        <b-col
                        v-if="type == 1"
                          cols="12"
                          sm="6"
                          md="4"
                          lg="4"
                        >
                          <label for="">Trimestre</label>
                          <v-select
                            v-model="payloadFilter.quarter"
                            :options="quarterOptions"
                            class="invoice-filter-select"
                            :reduce="(item) => item.value"
                          >
                            <template #selected-option="{ label }">
                              <span class="text-truncate overflow-hidden">
                                {{ label }}
                              </span>
                            </template>
                          </v-select>
                        </b-col>
                        
                        <b-col
                        v-if="type == 2"
                          cols="12"
                          sm="6"
                          md="4"
                          lg="4"
                        >
                          <label for="">Mois</label>
                          <v-select
                            
                            v-model="payloadFilter.month"
                            :options="monthOptions"
                            class="invoice-filter-select"
                            :reduce="(item) => item.value"
                          >
                            <template #selected-option="{ label }">
                              <span class="text-truncate overflow-hidden">
                                {{ label }}
                              </span>
                            </template>
                          </v-select>
                        </b-col>

                        <b-col
                        
                          cols="12"
                          sm="6"
                          md="4"
                          lg="4"
                        >
                          <label for="">Année</label>
                          <v-select
                            v-model="payloadFilter.year"
                            :options="yearOptions"
                            class="invoice-filter-select"
                            :reduce="(item) => item.value"
                          >
                            <template #selected-option="{ label }">
                              <span class="text-truncate overflow-hidden">
                                {{ label }}
                              </span>
                            </template>
                          </v-select>
                        </b-col>
                      
                      </b-row>
                    </b-col>
                    <b-col
                          cols="12"
                          sm="6"
                          md="2"
                          lg="2"
                          class="mt-2"
                        >
                          <b-button
                            variant="primary"
                            @click="applyFilterTauxStatisticsAction()"
                          >
                            <div v-if="isReLoadFilterData">
                              <span> Chargement ... </span>
                              <b-spinner small />
                            </div>

                            <span
                              v-else
                              class="text-nowrap font-medium-1"
                            >Filtrer</span>
                          </b-button>
                    </b-col>

                    <b-col
                      cols="12"
                      sm="6"
                      md="2"
                      lg="2"
                      class="mt-2"
                    >
                      <b-button
                        variant="primary"
                        @click="eraseTauxStatistics()"
                      >
                        <div v-if="isEraseFilterData">
                          <span> Chargement ... </span>
                          <b-spinner small />
                        </div>

                        <span
                          v-else
                          class="text-nowrap font-medium-1"
                        >Effacer</span>
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row>
                      <b-col
            
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-2"
                        >
                          <b-button
                            variant="primary"
                            @click="resetTauxStatistics()"
                          >
                            <div v-if="isReLoadDataTaux">
                              <span> Chargement ... </span>
                              <b-spinner small />
                            </div>
                            <span v-else class="text-nowrap font-medium-1">Actualiser</span>
                          </b-button>
                      </b-col>
              
                  </b-row>
                  <div
                    v-if="isBusinessManagersKpisLoading"
                    class="text-center text-info my-2"
                  >
                    <b-spinner
                      variant="info"
                      style="width: 3rem; height: 3rem;"
                    />
                  </div>

                  <b-row v-else>
                    <b-col
                      v-for="(item , index) in Object.entries(getTauxStatistics.rate)"
                      :key="index"
                      lg="4"
                      sm="6"
                      style="height:max-content !important;"
                    >
          
              <statistic-card-horizontal
                isTaux
                :icon="item[1].variation < 0 ? 'TrendingDownIcon': item[1].variation == 0 ? 'ArrowRightIcon' : 'TrendingUpIcon'"
                :color="item[1].variation < 0 ? 'danger': item[1].variation == 0 ? 'info':'success'"
                :statistic="item[0].includes('Taux de croissance') ? item[1].variation.toLocaleString() :item[1].current.toLocaleString()"
                :statistic-title="
                  item[0]"
                :statistic-description="
                  (item[1].variation  < 0 &&  item[1].previous_month !== undefined) ? `En diminution par rapport au mois de ${item[1].previous_month}` : (item[1].variation  > 0 &&  item[1].previous_month !== undefined ) ? `En croissance par rapport au mois de ${item[1].previous_month}` : (item[1].variation  == 0 &&  item[1].previous_month !== undefined ) ? `Constant par rapport au mois de ${item[1].previous_month}` : (item[1].variation  < 0 &&  item[1].previous_year !== undefined) ? `En diminution par rapport à l\'année  ${item[1].previous_year}` : (item[1].variation > 0 &&  item[1].previous_year !== undefined) ? `En croissance par rapport à l\'année ${item[1].previous_year}` : (item[1].variation == 0 &&  item[1].previous_year !== undefined) ? `Constant par rapport à l\'année ${item[1].previous_year}` : (item[1].variation < 0 &&  item[1].previous_quarter !== undefined) ? `En diminution par rapport au trimestre ${item[1].previous_quarter} de l'année ${item[1].previous_quarter_year}`  : (item[1].variation == 0 &&  item[1].previous_quarter !== undefined) ? `Constant par rapport au trimestre ${item[1].previous_quarter} de l'année ${item[1].previous_quarter_year}`  : `En croissance par rapport au trimestre  ${item[1].previous_quarter} de l'année ${item[1].previous_quarter_year}`
                 "
              />          

            </b-col>

                  </b-row>

                </app-collapse-item>

              </app-collapse>
          </b-tab>
    </b-tabs>
    </b-card>
  </div>


 
  <div v-else>
    <not-authorized />
  </div>

</template>

<script>
import {
  BCard,
  BCardText,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BRow,
  BFormGroup,
  BButton,
  BSpinner,
  BTabs,
  BTab
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCol,
    BRow,
    BFormGroup,
    flatPickr,
    BButton,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    StatisticCardHorizontal,
    BTabs,
    BTab,
    vSelect
  },

  data() {
    return {
      isNumbersStatisticsARHLoading: false,
      isEraseFilterData: false,
      isDateRangeStatisticsLoading: false,
      rangeDate: null,
      rangeRHDate: null,
      filterIsActive: false,
      isRangeStatisticsLoading: false,
      config: { mode: 'range' },
      isarhKpisLoading: true,
      statisticsItems: {},
      arhId: null,
      isBusinessManagersKpisLoading: true,
      caId: null,
      statisticsItems: [],
      isReLoadFilterData:false,
        isReLoadDataTaux: false,
        payloadFilter:{
          quarter: "",
          month: "",
          year: ""
        },
        typeOptions: [
        {
          label: 'Trimestre',
          value: 1,
        },
        {
          label: 'Mois',

          value: 2,
        },
        {
          label: 'Année',

          value: 3,
        },
      ],
      monthOptions: [
        { value: 1, label: "Janvier" },
        { value: 2, label: "Février" },
        { value: 3, label: "Mars" },
        { value: 4, label: "Avril" },
        { value: 5, label: "Mai" },
        { value: 6, label: "Juin" },
        { value: 7, label: "Juillet" },
        { value: 8, label: "Août" },
        { value: 9, label: "Septembre" },
        { value: 10, label: "Octobre" },
        { value: 11, label: "Novembre" },
        { value: 12, label: "Décembre" },
      ],
      type: "",
      quarterOptions: [
        {
          label: 'Premier trimestre',
          value: 1,
        },
        {
          label: 'Deuxième trimestre',

          value: 2,
        },
        {
          label: 'Troisième trimestre',

          value: 3,
        },
        {
          label: 'Quatrième trimestre',

          value: 4,
        },
      ],
    }
  },

  computed: {
    ...mapGetters('roles', ['getAssistantRHList']),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters(['isBusinessDeveloperListLoading','isAssistantRHListLoading', 'isRHStatisticsLoading', 'isNumbersStatisticsLoading' ]),
    ...mapGetters('statistics', [
        'getTauxStatistics',
        'getRHStatistics',
        
      ]), 

    yearOptions() {
        const data = []
        for (let i = 2021; i <= Number((new Date()).getFullYear().toString()); i++) {
          data.push({
            label: i.toString(),
            value: i.toString(),
          })
        }
        return data
      },
  },

  watch: {
    rangeDate(val) {
      if (val.split('to').length === 2) {
        this.applyGetRangeStatisticsAction(val)
        this.applyRHRangeStatisticsAction(val)
      }
    },
    rangeRHDate(val) {
      if (val.split('to').length === 2) {
        this.applyRHRangeStatisticsAction(val)
      }
    },

    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue);
        keys.forEach((key) => {
          if (newValue[key] == null) {
            this.resetTauxStatistics();
            newValue[key] = "";
          }
        });
      },
      deep: true,
    },
  
  },

  created() {

  },

  methods: {
    ...mapActions('statistics', [
      'getBusinessDeveloperStatisticsAction',
      'getARHStatisticsAction',
      'getBusinessDeveloperStatisticsByRangeAction',
      'getARHStatisticsByRangeAction',
      'getTauxStatisticsARHByIdAction',
      'filterTauxStatisticsARHByIdAction',
      'getRHStatisticsAction',
      'getRHRangeStatisticsAction',
    ]),

    getARHKpis(id) {
      this.arhId = id
      this.rangeDate = null
      this.filterIsActive = false
      this.isarhKpisLoading = true
        this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', true)
      this.isRangeStatisticsLoading = true
      this.getARHStatisticsAction(id).then(response => {
        this.isarhKpisLoading = false
        this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
      this.isRangeStatisticsLoading = false
        // console.log(response)
        this.statisticsItems = response.data
      })
      
    },
    resetARHStatistics() {
        this.isReLoadDataTaux = true,
        this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', true)

          this.getARHStatisticsAction(this.arhId)
          .then(() => {
            this.rangeDate = null
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.rangeDate = null
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
          })
             
      },

    resetRHStatistics() {
      this.isDateRangeStatisticsLoading = true
      this.$store.commit('SET_IS_RH_STATISTICS_LOADING', true)
      this.getRHStatisticsAction()
        .then(() => {
          this.rangeRHDate = null
      this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_RH_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
      this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_RH_STATISTICS_LOADING', false)
        })
    },

    applyRHRangeStatisticsAction(val) {
      const payload = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      this.isDateRangeStatisticsLoading = true
      this.$store.commit('SET_IS_RH_STATISTICS_LOADING', true)
      this.getRHRangeStatisticsAction(payload)
        .then(response => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_RH_STATISTICS_LOADING', false)
        })
        .catch(error => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_RH_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
    },

    getARHKpisTaux(id) {
      this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
      this.arhId = id
      this.rangeDate = null
      this.filterIsActive = false
      this.isBusinessManagersKpisLoading = true
      this.getTauxStatisticsARHByIdAction(id).then(response => {
        this.isBusinessManagersKpisLoading = false
        // console.log(response)
        this.getTauxStatistics = response.data
      })
     
    },

    resetTauxStatistics() {
        this.isReLoadDataTaux = true,
        this.isBusinessManagersKpisLoading = true

          this.getTauxStatisticsARHByIdAction(this.arhId)
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isReLoadDataTaux = false,
            this.isBusinessManagersKpisLoading = false
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isReLoadDataTaux = false,
            this.isBusinessManagersKpisLoading = false
          })
             
      },

      eraseTauxStatistics() {
        this.isEraseFilterData = true,
        this.isBusinessManagersKpisLoading = true

          this.getTauxStatisticsARHByIdAction(this.caId)
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.isBusinessManagersKpisLoading = false
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.isBusinessManagersKpisLoading = false
          })
             
      },

    applyFilterTauxStatisticsAction(){
        this.isReLoadFilterData = true
        this.isBusinessManagersKpisLoading = true
        const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
      if (allIsNUl) {
        this.isReLoadFilterData = false
        this.isBusinessManagersKpisLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "CheckIcon",
              text: "Veuillez sélectionner au moins un champ du filtre.",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      }
      else{
        this.filterTauxStatisticsARHByIdAction({ id: this.arhId, payload: this.payloadFilter })
        .then(response => {
          this.isBusinessManagersKpisLoading = false
          this.isReLoadFilterData = false
          console.log(response.data)
        })
        .catch(error => {
          this.isBusinessManagersKpisLoading = false
          this.isReLoadFilterData = false
          console.log(error)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Erreur",
                icon: "AlertTriangleIcon",
                text: error.response.data.message,
                variant: "danger",
              },
            },
            {
              position: "top-center",
            }
          );
        })
      }
      },


    applyGetRangeStatisticsAction(val) {
      const payload = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      this.isRangeStatisticsLoading = true
      this.isarhKpisLoading = true
      this.getARHStatisticsByRangeAction({
        arhId: this.arhId,
        payload,
      })
        .then(response => {
          this.isRangeStatisticsLoading = false
          this.isarhKpisLoading = false
          this.statisticsItems = response.data

          this.filterIsActive = true
        })
        .catch(error => {
          this.isRangeStatisticsLoading = false
          this.isarhKpisLoading = false
          /* if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else { */
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
         /*  } */
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
